.login {
  font-family: "Roboto", sans-serif;
}
.login h2 {
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
}
.login .loginbgcontainer {
  background-color: #fdf3f3;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.login .logincontainer {
  padding-left: 50px;
  text-align: center;
}
.login .btn-primary {
  background-color: #333;
  border-color: #333;
  border-radius: 10px;
}
.login input {
  border-radius: 10px;
  border-color: #333;
}
.login .btn-link {
  text-decoration: none;
  color: #1757d7;
}
