header .welcometext {
  margin-top: 20px;
}
.notification {
  min-width: 400px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.notification h6 {
  padding: 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.notification button.btn-icon {
  vertical-align: middle;
  margin-top: 0;
  margin-left: 0;
}
.notification button.btn-icon.unread {
  background-color: #f3c7cc;
  border: 1px solid #f3c7cc;
}
.hours {
  color: #797979;
}
.notification h6 .row div:first-child {
  padding-top: 10px;
}
.notification h6 button.btn-outline-dark {
  min-width: 70px;
  font-size: 14px !important;
  padding: 2px 0;
  vertical-align: middle;
}
.notification .list-group {
  border: none;
  margin: 0 20px;
  font-size: 14px;
}
.notification .list-group-item {
  border-left: 0;
  border-right: 0;
  margin-left: 0;
  padding-left: 0;
}
.notification .list-group-item:first-child {
  border-radius: 0;
  border-top: 0;
}
.notification .list-group-item:last-child {
  border-radius: 0;
  border-bottom: 0;
}

.leaderboard {
  margin-top: 20px;
}

.leaderboard .list-group {
  border: none;
  margin: 0;
  padding: 0;
}
.leaderboard .list-group-item {
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.leaderboard .list-group-item .green {
  color: #419c3e;
}
.leaderboard .list-group-item .red {
  color: #f55a69;
}
.leaderboard .userImageHolder {
  width: 48px;
  height: 48px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
  padding-top: 10px;
  border-radius: 15px;
  font-size: 16px;
  background-color: #f3c7cc;
}
.leaderboard .userImageHolderimg {
  margin-bottom: 0;
  width: 48px;
  height: 48px;
  text-align: center;
  position: relative;
}
.leaderboard .userImageHolder .online,
.leaderboard .userImageHolderimg .online {
  width: 12px;
  right: -3px;
  height: 12px;
  border: 1px solid white;
}
.leaderboard .userImageHolder .offline,
.leaderboard .userImageHolderimg .offline {
  width: 12px;
  right: -3px;
  height: 12px;
  border: 1px solid white;
  background-color: #f55a69;
}
.leaderboard .uname {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leaderboard .col-7 {
  padding-left: 20px;
}
.profileoverlay {
  background-color: #000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.7;
  text-align: center;
  border-radius: 30px;
}
.profileoverlay button {
  width: 40px;
  margin-top: 70px;
  min-width: auto;
  height: 40px;
  border-radius: 15px !important;
}
