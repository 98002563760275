.sidebar {
  background-color: #f6f6f6;
  text-align: center;
  padding: 25px;
  min-height: 100vh;
  position: relative;
}
.sidebar .logocontainer {
  margin-bottom: 30px;
}
.sidebar .profilecontainer {
  margin-bottom: 30px;
}
.sidebar .linkcontainer li {
  cursor: pointer;
}
.sidebar .logoutbutton {
  margin-top: 100px;
  border-radius: 15px !important;
  position: absolute;
  bottom: 20px;
  left: 60px;
}
