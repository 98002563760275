.App {
  text-align: center;
}
.pagespinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 5rem;
  height: 5rem;
}
button {
  min-width: 150px;
  margin: 10px 0 10px 10px;
  border-radius: 5px !important;
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background: #e8eefb;
  border-color: #e8eefb;
  box-shadow: none;
  color: #1757d7;
}
.btn-primary {
  border-color: #1757d7;
  background: #1757d7;
}
.btn-primary span {
  font-size: 12px;
  display: inline-block;
  padding: 0 5px;
}
button > i {
  margin-right: 5px;
}
.btn-icon {
  background-color: #ebebeb;
  border-color: #ebebeb;
  min-width: auto;
  border-radius: 15px;
  margin-right: 5px;
}

.btn-icon i {
  margin-right: 0;
}
.logoutbutton {
  background-color: #ebebeb;
  padding: 10px 20px;
}
.linkcontainer {
  max-width: 250px;
  list-style: none;
  margin: 0 10px;
  padding: 2px 10px 5px 10px;
  background-color: #f6f6f6;
  text-align: left;
}
.linkcontainer li {
  color: #333333;
  list-style: none;
  font-size: 16px;
  margin: 5px 0 0 0;
  padding: 0;
}
.linkcontainer .active {
  font-weight: 700;
  color: #d84555;
}
.linkcontainer .active .btn-icon {
  border: none;
  background: rgba(237, 129, 140, 0.3);
}
.logocontainer {
  font-weight: 500;
  padding: 10px;
  font-size: 24px;
}
.logocontainer button {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 24px;
}
.logocontainer .logo {
  vertical-align: top;
  width: 40px;
  height: 30px;
  margin-right: 5px;
}
.logocontainer span {
  display: inline-block;
}
.btn-notify {
  background-color: #ebebeb;
  border-color: #ebebeb;
  min-width: auto;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  border-radius: 15px;
}
.notify {
  background-color: #f55a69;
  width: 5px;
  position: absolute;
  left: 28px;
  top: 13px;
  height: 5px;
  border-radius: 50%;
}
.profilecontainer {
  text-align: center;
}
.profilecontainer h4 {
  font-size: 14px;
  cursor: pointer;
}
.profilecontainer h5 {
  font-size: 12px;
  color: #797979;
  cursor: pointer;
}
.imagecontainer {
  margin: 0 auto;
  position: relative;
  max-width: 109px;
  margin-bottom: 25px;
  cursor: pointer;
}
.imagecontainer.active img {
  border: 5px solid #efb5bb;
}
.imagecontainer img {
  border: 5px solid #f6f6f6;
  border-radius: 35px;
  width: 119px;
}
.online,
.offline {
  background-color: #419c3e;
  width: 18px;
  border: 3px solid #fff;
  position: absolute;
  right: -5px;
  top: 0;
  height: 18px;
  border-radius: 50%;
}
.imagecontainer.active .online {
  right: -10px;
  top: 0;
}
.imagecontainer h6 {
  background-color: #d18e2a;
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: #fff;
  font-size: 12px;
  padding: 7px 0;
  font-weight: normal;
  width: 100%;
  margin-bottom: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.page-title {
  font-weight: normal;
}

.panelps {
  margin: 10px;
  border-radius: 15px;
}
.panelps .card-title {
  font-size: 16px;
}
.panelps .card-title i {
  margin-right: 10px;
}
.panelps .card-footer {
  font-size: 14px;
}
.panelps .card-footer .card-footer-text {
  margin-right: 30px;
}
.status .badge {
  font-weight: normal;
  display: inline-block;
}
.badge.live {
  background-color: #d7f2d6 !important;
  color: #419c3e !important;
  margin-left: 20px;
  font-weight: normal;
}
.status .badge.left {
  background-color: #ffe3e7 !important;
  color: #f55a69;
}
.status .badge.up,
.status .badge.down {
  background-color: #ebebeb !important;
  color: #333333 !important;
}
.status .badge.up i {
  color: #419c3e;
}
.welcometext {
  font-size: 14px;
}
.welcometext span {
  display: inline-block;
  margin-left: 10px;
}
.status .badge.down i {
  display: inline-block;
  transform: rotate(180deg);
  color: #f55a69;
}
.scoresummary {
  text-align: center;
  padding: 20px 0;
}
.scoresummary h3,
.profilescode h3 {
  font-size: 48px;
  font-weight: bolder;
}
.scoresummary h5,
.profilescode h5 {
  font-size: 14px;
  font-weight: normal;
}
.loginbgtext {
  font-size: 16px;
  text-align: left;
  height: auto;
  margin: 0 auto;
}
.loginbgimgcontainer {
  text-align: center;
  padding: 0 0 20px 0;
}
.datetimelabel {
  font-size: 16px;
  color: #999;
  font-weight: 500;
}
.profilescode {
  background: #f7dadd;
  height: 160px;
  width: 160px;
  text-align: center;
  border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
}
.profilescode h3 {
  padding-top: 30px;
}
.profilescode h5 {
  width: 55px;
  color: #797979;
  margin-left: 50px;
}
.profileimagecontainer {
  position: relative;
  height: 160px;
  width: 160px;
  border-radius: 25px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 25px;
}
.profileimagecontainer .profileimg {
  height: 160px;
  border-radius: 25px;
  width: 160px;
}
.profileimagecontainer h6 {
  background-color: #d18e2a;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 18px;
  padding: 7px 0;
  font-weight: normal;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.form-control {
  color: #333;
  border-radius: 10px;
  border: 1px solid #333333;
}
.subtitle {
  font-size: 24px;
  margin-left: 10px;
  font-weight: 500;
}
.form-check-label {
  font-size: 16px;
}
.form-check-input {
  margin-top: 10px;
  vertical-align: middle;
}
.form-check-input:checked {
  background-color: #333333;
  border-color: #333333;
}
.form-check-input:focus {
  border-color: #333333;
  box-shadow: none;
}
.form-switch i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
}
.rightcontainer {
  padding: 20px 20px 0 0;
}
.scorecontainer {
  padding: 20px 0;
}

.buttonbar {
  margin-top: 120px;
}
.green {
  color: #419c3e;
}
.red {
  color: #f55a69;
}
.btnbar button {
  text-align: left;
}
.br-10 {
  border-radius: 10px !important;
}
.homecardcontainer {
  padding: 7% !important;
}
.settingscontainer .subtitle {
  margin: 25px 10px;
}
.settingscontainer .panelps {
  max-width: 500px;
}
.sessiontime {
  padding-top: 20px;
  padding-left: 10px;
}
.createsessionrightcontainer {
  margin-top: 50px;
  padding-top: 20px;
  padding-left: 50px;
  border-left: 1px solid #999;
}
.createsessionrightcontainer .form-check-input {
  vertical-align: middle !important;
  margin-top: 5px !important;
}
.createsession {
  padding-top: 20px;
  padding-left: 50px;
  border-left: 1px solid #999;
  padding-bottom: 25px;
}
.createsession select {
  border: 1px solid #333;
  border-radius: 10px;
}
.createsession button {
  margin: 0 !important;
  margin-top: 25px !important;
}
.multiimage {
  position: relative;
  font-size: 12px;
  margin-bottom: 5px;
  padding-left: 90px;
}

.multiimage .griduser {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px 10px;
}
.multiimage .griduser:nth-child(1) {
  left: 0;
  background-color: #f3c7cc;
  z-index: 3;
}
.multiimage .griduser:nth-child(2) {
  left: 20px;
  background-color: turquoise;
  z-index: 2;
}
.multiimage .griduser:nth-child(3) {
  left: 40px;
  background-color: violet;
  z-index: 1;
}
.multiimage .griduser:nth-child(4) {
  left: 60px;
  background-color: #d18e2a;
  z-index: 0;
}


.circular{
  height: 60px;
  width: 60px;
  position: relative;
  transform:scale(2);
}
.circular .inner{
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  margin: -20px 0 0 -20px;
  background: #fff;
  border-radius: 100%;
 
}
.circular .number{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index:10;
  font-size:10px;
  font-weight:500;
  color:#333;
}
.circular .bar{
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  clip: rect(0px, 60px, 60px, 30px);
}
.circle .bar .progress{
  position: absolute;
  height: 100%;
  width: 100%;
  clip: rect(0px, 30px, 60px, 0px);
  background: #4158d0;
}
.circle .left .progress{
  z-index:1;
  animation: left 4s linear both;
}
@keyframes left{
  100%{
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index:3;
 
}
.circle .right .progress{
  animation: right 4s linear both;
  animation-delay:4s;
}
@keyframes right{
  100%{
    transform: rotate(180deg);
  }
}

.donut {
  position: relative;
  
}
.timesleft {
  position: absolute;
  text-align: center;
  left: 75px;
  font-size: 26px;
  top: 30px;
  z-index: 99;
}
.timestotal {
  position: absolute;
  left: 75px;
  text-align: center;
  font-size: 16px;
  background-color: #fff;
  width: 40px;
  top: 90px;
  z-index: 99;
}

.hourslist {
  display: flex;
  padding: 10px 0;
  margin: 15px;
  min-height: 50px;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
}
.hour {
flex: 1;
text-align: center;
font-size: 11px;
}
.hour:first-child {
  text-align: left;
}
.hour:last-child {
  text-align: right;
}
.hourbutton {
  width: 50px;
  font-size: 10px;
  padding: 5px 0;
  min-width: auto;
  position: absolute;
  top: -5px;
}


.react-calendar-heatmap text {
  font-size: 3px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 3px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #fff;
}

.react-calendar-heatmap .color-filled {
  fill: #1757D7;
  opacity: 0.1;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #D84555;
  opacity: 0.1;
}
.react-calendar-heatmap .color-github-1 {
  fill: #D84555;
  opacity: 0.3;
}
.react-calendar-heatmap .color-github-2 {
  fill: #D84555;
  opacity: 0.5;
}
.react-calendar-heatmap .color-github-3 {
  fill: #D84555;
  opacity: 0.7;
}
.react-calendar-heatmap .color-github-4 {
  fill: #D84555;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

.buttonStyle01 {
  min-width: auto;
  font-size: 11px; 
  padding: 3px;
  margin: 2px;
}
.prevsession {
  font-size: 12px;
  min-width: 60px;
  padding: 1px 3px;
  text-align: center !important;
}